import React, { useState, useCallback, memo, useRef, useEffect } from "react"
// import Card from "../layout/Card"
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  HStack,
  Icon,
  Input,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Select,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useTheme,
  VStack,
} from "@chakra-ui/react"
import { FiUpload } from "react-icons/fi"
import { FileLite } from "../../../../index.d"
import { compact } from "lodash"
import axios from "axios"
import Section from "gatsby-theme-wild-child/src/components/Section"

const options = [
  {
    value: "tax",
    label: "Tax",
  },
  {
    value: "businesSetup",
    label: "Business Setup",
  },
]

function Upload(props) {
  const [files, setFiles] = useState<FileLite[]>([])
  const [loading, setLoading] = useState(false)
  const [dragOver, setDragOver] = useState(false)
  const dropzoneRef = useRef<any>(null)
  const [listItemData, setListItemData] = useState([])
  const [selectedUrls, setSelectedUrls] = useState([])
  const [selectAll, setSelectAll] = useState(false)
  const [currentUrls, setCurrentUrls] = useState("")
  const [nameSpace, setNameSpace] = useState("")
  const [openModal, setopenModal] = useState(false)
  const [error, setError] = useState("")
  const [namespaceListing, setNamespaceListing] = useState([])

  const MAX_FILES = 30
  const MAX_SIZE_MB = 10

  const handleFileChange = useCallback(
    async (selectedFiles: FileList | null) => {
      if (selectedFiles && selectedFiles.length > 0) {
        setError("")

        if (files.length + selectedFiles.length > MAX_FILES) {
          setError(`You can only upload up to ${MAX_FILES} files.`)
          if (dropzoneRef.current) {
            ; (dropzoneRef.current as any).value = ""
          }
          return
        }

        setLoading(true)

        const uploadedFiles = await Promise.all(
          Array.from(selectedFiles).map(async file => {
            // Check the file type

            if (
              file.type.match(
                /(text\/plain|application\/(pdf|msword|vnd\.openxmlformats-officedocument\.wordprocessingml\.document)|text\/(markdown|x-markdown))/
              )
            ) {
              const formData = new FormData()
              formData.append("file", file)
              formData.append("filename", file.name)

              try {
                const processFileResponse = await axios.post(
                  "/.netlify/functions/form-data",
                  formData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                )

                if (processFileResponse.status === 200) {
                  const text = processFileResponse.data.text
                  const meanEmbedding = processFileResponse.data.meanEmbedding
                  const chunks = processFileResponse.data.chunks

                  const fileObject: FileLite = {
                    name: file.name,
                    url: URL.createObjectURL(file),
                    type: file.type,
                    size: file.size,
                    expanded: false,
                    embedding: meanEmbedding,
                    chunks,
                    extractedText: text,
                  }
                  return fileObject
                } else {
                  return null
                }
              } catch (err: any) {
                return null
              }
            } else {
              alert(
                `Invalid file type or size. Only TXT, PDF, DOCX or MD are allowed, up to ${MAX_SIZE_MB}MB.`
              )
              return null // Skip this file
            }
          })
        )

        // Filter out any null values from the uploadedFiles array
        const validFiles = compact(uploadedFiles)

        // // Set the files state with the valid files and the existing files
        setFiles(prevFiles => [...prevFiles, ...validFiles])
        setFiles(prevFiles => [...prevFiles, ...validFiles])

        setLoading(false)
      }
    },
    [files, setFiles, MAX_SIZE_MB, MAX_FILES]
  )

  const handleDragEnter = useCallback((event: React.DragEvent) => {
    event.preventDefault()
    setDragOver(true)
  }, [])

  const handleDragOver = useCallback((event: React.DragEvent) => {
    event.preventDefault()
  }, [])

  const handleDragLeave = useCallback((event: React.DragEvent) => {
    event.preventDefault()
    setDragOver(false)
  }, [])

  const handleListingNamespace = () => {
    axios
      .get(`${process.env.GATSBY_API_BASE_URL}/namespaces/list`, {})
      .then((response: any) => {
        const items = response?.data.response.map(namespace => ({
          ...namespace,
          value: namespace?.title,
          label: namespace?.title,
        }))
        setNamespaceListing(items)
      })
      .catch(error => {
        console.error(error, "nameSpace error")
      })
  }
  useEffect(() => {
    handleListingNamespace()
  }, [])

  const handleDrop = useCallback(
    (event: React.DragEvent) => {
      event.preventDefault()
      setDragOver(false)
      const droppedFiles = event.dataTransfer.files
      handleFileChange(droppedFiles)
    },
    [handleFileChange]
  )

  const handleCheckboxChange = (event, index) => {
    const checked = event.target.checked
    let copyItems: any = [...listItemData]
    if (checked) {
      copyItems[index] = { ...copyItems[index], isChecked: true }
    } else {
      copyItems[index] = { ...copyItems[index], isChecked: false }
    }
    setListItemData(copyItems)
  }

  const handleStatusChange = (status, index) => {
    let copyItems: any = [...listItemData]
    copyItems[index] = { ...copyItems[index], status }
    setListItemData(copyItems)
  }

  const handleSelectAllChange = event => {
    const checked = event.target.checked
    const copyItems = [...listItemData]
    let items: any = []
    if (checked) {
      items = copyItems.map((it: any) => {
        return {
          ...it,
          isChecked: true,
        }
      })
    } else {
      items = copyItems.map((it: any) => {
        return {
          ...it,
          isChecked: false,
        }
      })
    }

    setListItemData(items)
  }

  const handleTagInputKeyDown = event => {
    if (event.key === "Enter" || event.key === ",") {
      event.preventDefault()
      addUrls(event)
    }
  }

  const addUrls = event => {
    if (currentUrls.trim() !== "") {
      const urls = currentUrls.split(",").map((url: any) => {
        return {
          name: url,
          url: url,
          isChecked: false,
          status: "Added",
        }
      })
      const items: any = [...listItemData, ...urls]
      setListItemData(items)
      setCurrentUrls("")
    }
  }

  const handleTagInputChange = event => {
    setCurrentUrls(event.target.value)
  }

  const onClose = () => {
    setNameSpace("")
    setopenModal(false)
  }

  const handleOpenModal = () => {
    setopenModal(true)
  }

  function handleChange(event) {
    setNameSpace(event.target.value)
  }

  // Function to send PDF to the backend
  const sendPDFToBackend = async item => {
    try {
      const response = await fetch(`${process.env.GATSBY_API_BASE_URL}/references`, {
        method: "POST",
        body: JSON.stringify({
          urls: [item?.name],
          namespace: nameSpace,
        }),
      })

      if (!response.ok) {
        throw new Error("Failed to upload PDF")
      }
      return "Successfully"
    } catch (error) {
      return "Rejected"
    }
  }

  // Function to handle PDF upload
  const handleUploadPDF = async item => {
    const result = await sendPDFToBackend(item)
    item.progress = result === "Successfully" ? "Uploaded" : "Rejected"
    setListItemData([...listItemData])
  }

  // Function to handle form submission
  const handleSubmit = async () => {
    const items = [...listItemData]
    for (let i = 0; i < items.length; i++) {
      if (items[i].isChecked) {
        try {
          handleStatusChange("InProgress", i)
          await handleUploadPDF(items[i])
          handleStatusChange("Completed", i)
        } catch (error) {
          handleStatusChange("Error", i)
          console.error(`Error uploading PDF for item ${i}: ${error}`)
        }
      }
    }
  }

  const handleScrapeUrls = () => {
    handleSubmit()
    onClose()
  }

  return (
    <Section mt={8} minH="100vh">
      <VStack
        w="full"
        spacing={12}
        pb={10}
        h="full"
        justify="center"
        as="label"
        htmlFor="dropzone-file"
        ref={dropzoneRef}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <VStack spacing={4} w="full" maxW="container.sm">
          <Heading fontSize="2xl" color="red.full">
            Build your Chatbot's Knowledge Base
          </Heading>
          <Text textAlign="center">
            Upload any files or websites that you want your Chatbot to be able
            to answer questions about. Make sure they are very high quality.
          </Text>
        </VStack>
        <VStack
          w="full"
          maxW="container.sm"
          bg={dragOver ? "gray.100" : "gray.50"}
          border="2px dashed"
          borderColor="gray.200"
          p={12}
          rounded="md"
          cursor="pointer"
          _hover={{
            bg: "gray.100",
          }}
        >
          <Icon as={FiUpload} color="gray.400" boxSize="32px" />
          <Text fontSize="sm">
            <Text as="span" fontWeight="semibold">
              Click to upload
            </Text>{" "}
            or drag and drop
          </Text>
          <Text fontSize="sm">TXT, PDF, DOCX or MD (max 30MB per file)</Text>
          <Text fontSize="sm">You can upload up to 75 more files</Text>
          <Input
            id="dropzone-file"
            type="file"
            className="hidden"
            multiple
            onChange={event => handleFileChange(event.target.files)}
          />
        </VStack>
      </VStack>
      <VStack w="full" pb={5} h="full" justify="center">
        <HStack w="full" maxW="container.sm" h="full" justify="center">
          <Heading fontSize="xl" color="">
            OR
          </Heading>
        </HStack>
      </VStack>

      <VStack w="full" pb={10} h="full" justify="center">
        <HStack w="full" maxW="container.sm" pb={10} h="full" justify="center">
          <Input
            value={currentUrls}
            size="lg"
            fontSize="sm"
            placeholder="Add a URL or sitemap"
            bg="gray.50"
            onChange={handleTagInputChange}
            onKeyDown={handleTagInputKeyDown}
          />
        </HStack>

        <VStack spacing={2} w="full" maxW="container.sm">
          <Flex alignItems="center" justifyContent="flex-start" w="full">
            {listItemData?.length > 0 && (
              <Checkbox
                colorScheme="red"
                px={4}
                isChecked={listItemData.every(item => item?.isChecked)}
                onChange={handleSelectAllChange}
              >
                Select All
              </Checkbox>
            )}
          </Flex>
          <Box maxHeight="350px" overflow="auto" w="full" p={0}>
            <List spacing={2} w="full" p={0} styleType="none">
              {listItemData.map((item, itemIndex) => (
                <ListItem
                  key={itemIndex}
                  borderRadius="md"
                  listStyleType="none"
                  minHeight="48px"
                  bg="#f2f2f2"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  px={4}
                >
                  <Checkbox
                    colorScheme="red"
                    w="80%"
                    overflow="hidden"
                    isChecked={item.isChecked}
                    onChange={event => handleCheckboxChange(event, itemIndex)}
                  >
                    {item.name}
                  </Checkbox>
                  <Badge
                    variant="subtle"
                    colorScheme={
                      item.status === "Completed" ? "green" : "yellow"
                    }
                  >
                    {item.status}
                  </Badge>
                </ListItem>
              ))}
            </List>
          </Box>
        </VStack>
        {listItemData?.length > 0 && (
          <HStack
            w="full"
            maxW="container.sm"
            display="flex"
            justifyContent={"end"}
          >
            <Button bg="red.full" isLoading={loading} onClick={handleOpenModal}>
              Scrape
            </Button>
          </HStack>
        )}
      </VStack>

      <Modal isOpen={openModal} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent maxW="500px">
          <ModalHeader>Select Namespace </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Select
              iconColor="gray.200"
              name="namespace"
              borderWidth="1px"
              borderColor="gray.50"
              borderRadius="md"
              size={"md"}
              bg={nameSpace ? "white" : "gray.50"}
              value={nameSpace}
              // onBlur={handleBlur}
              onChange={handleChange}
            >
              <option value="">Select Namespace</option>
              {namespaceListing.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          </ModalBody>
          <ModalFooter>
            <Button
              bg="gray.100"
              color="dark.full"
              mr={3}
              onClick={async () => {
                onClose()
              }}
            >
              Cancel
            </Button>
            <Button
              bg="red.full"
              mr={3}
              onClick={async () => {
                handleScrapeUrls()
              }}
            >
              done
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Section>
  )
}

export default memo(Upload)
