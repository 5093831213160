import React from "react"
import AuthContent from "../gatsby-theme-wild-child/components/auth/AuthContent"
import Upload from "../gatsby-theme-wild-child/components/references/uploads"

function References() {
  return (
    <AuthContent>
      <Upload />
    </AuthContent>
  )
}

export default References

export const Head = () => (
  <>
    <title>TaxGPT by Virtuzone | References</title>
    <meta
      name="facebook-domain-verification"
      content="0qis6k25boblo98elnosvjz5ws7gtm"
    />
    <meta name="google-site-verification" content="fdkrhMVPDQOPHf3A4czsCkEuBbJ_lkvh8yj01E6fn4Q" />
  </>
)
